import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL, matchSubscriptions, errorResponse } from "../shared/Utils";
import { documentTypeNames } from "../shared/constants";
import toast from "../hooks/toast";
import featureFlag from "../featureToggler/featureFlag";
import { getStoreValues } from "./commonSlice";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    enableLoading: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrDescription: "",
    },
    isLoadingEntityUseCodeMappings: false,
    entityUseCodeMappings: [],
};

export const exemptionReasonSlice = createSlice({
    name: "exemptionReason",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setIsLoadingEntityUseCodeMappings: (state, action) => {
            state.isLoadingEntityUseCodeMappings = action.payload;
        },
        setEntityUseCodeMappings: (state, action) => {
            state.entityUseCodeMappings = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setIsLoadingEntityUseCodeMappings,
    setEntityUseCodeMappings,
} = exemptionReasonSlice.actions;

export const selectIsLoading = state => {
    return state.exemptionReason.isLoading;
};
export const selectPage = state => {
    return state.exemptionReason.page;
};
export const selectFormState = state => {
    return state.exemptionReason.formState;
};
export const selectIsDeleting = state => {
    return state.exemptionReason.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.exemptionReason.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.exemptionReason.enableLoading;
};
export const selectIsLoadingEntityUseCodeMappings = state => {
    return state.exemptionReason.setIsLoadingEntityUseCodeMapping;
};
export const selectEntityUseCodeMappings = state => {
    return state.exemptionReason.entityUseCodeMappings;
};

export const fetchExemptionReasons =
    (paginateDetails, sort = "name ASC", top = 20, skip = 0) =>
    async (dispatch, getState) => {
        const { isloading, formState } = getState().exemptionReason;
        const { nameOrDescription } = formState;
        if (isloading) {
            return;
        }
        dispatch(setIsLoading(true));

        let filterString = `documentType.name eq "${documentTypeNames.salesAndUseTax}" AND documentType.outgoing eq false`;
        if (nameOrDescription) {
            filterString += ` and name like '%${formState?.nameOrDescription}%' or description like '%${formState?.nameOrDescription}%' `;
        }
        filterString += ` and denotesExposure eq false and name != NON-DELIVERABLE`;
        const isTelecom = matchSubscriptions(
            featureFlag.afcContent.associateTaxTypeData.subscription,
            dispatch(getStoreValues("session.subscriptions"))
        );
        if (!isTelecom) {
            filterString += ` AND isTelecom eq false`;
        }
        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-reasons`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (errorResponse(response?.data?.[0])?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setIsLoading(false));
    };
export const deleteExemptReasonAsync = (id, exemptReason) => async (dispatch, getState) => {
    const { isDeleting } = getState().exemptionReason;

    if (isDeleting) {
        return false;
    }

    dispatch(setIsDeleting(true));
    const response = await axios.delete(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-reasons/${id}`,
        { withCredentials: true }
    );
    if (response?.data?.success) {
        showToast(
            "success",
            `Exemption reason "${exemptReason}" has deleted successfully`,
            "deleteExemptReasonToast",
            false
        );
        dispatch(fetchExemptionReasons());
    } else {
        showToast(
            "error",
            `"${exemptReason}" is currently being used on one or more certificates. Remove this reason from all certificates before deleting it.`,
            "deleteExemptReasonToast",
            false
        );
    }
    dispatch(setIsDeleting(false));
    return response;
};

export const AddOrUpdateExemptionReason =
    (addOrEdit, payload, taxCodeId, entityUseCode, oldEntityUseCode) =>
    async (dispatch, getState) => {
        const { isAddOrEditLoading } = getState().exemptionReason;
        if (isAddOrEditLoading) return false;
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "add") {
            await axios
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-reasons`,
                    payload,
                    { withCredentials: true }
                )
                .then(async x => {
                    if (entityUseCode && entityUseCode !== "no-assign") {
                        const response = await axios.post(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-reasons/${x.data.id}/entity-use-code-mapping`,
                            { entityUseCode },
                            { withCredentials: true }
                        );

                        if (response.status === 400) {
                            showToast(
                                "error",
                                `There was a problem associating the entity use code to the exempt reason.`,
                                "",
                                false
                            );
                        }
                    }

                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            `Exempt reason with same name already exists.`,
                            "",
                            false
                        );
                    }
                    if (x.status === 201) {
                        showToast("success", `Exempt reason added.`, "", false);
                        success = true;
                        dispatch(fetchExemptionReasons());
                    }
                });
        } else {
            await axios
                .put(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-reasons/${taxCodeId}`,
                    payload,
                    { withCredentials: true }
                )
                .then(async x => {
                    if (entityUseCode && entityUseCode !== "no-assign" && oldEntityUseCode !== "") {
                        const response = await axios.put(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-reasons/${x.data.id}/entity-use-code-mapping`,
                            { entityUseCode },
                            { withCredentials: true }
                        );

                        if (response.status === 400) {
                            showToast(
                                "error",
                                `There was a problem associating the entity use code to the exempt reason.`,
                                "",
                                false
                            );
                        }
                    } else if (entityUseCode && oldEntityUseCode === "") {
                        const response = await axios.post(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-reasons/${x.data.id}/entity-use-code-mapping`,
                            { entityUseCode },
                            { withCredentials: true }
                        );

                        if (response.status === 400) {
                            showToast(
                                "error",
                                `There was a problem associating the entity use code to the exempt reason.`,
                                "",
                                false
                            );
                        }
                    } else if (entityUseCode && entityUseCode === "no-assign") {
                        const response = await axios.delete(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-reasons/${x.data.id}/entity-use-code-mapping`,
                            { withCredentials: true }
                        );

                        if (response.status === 400) {
                            showToast(
                                "error",
                                `There was a problem deleting the entity use code to the exempt reason.`,
                                "",
                                false
                            );
                        }
                    }

                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            `Exempt reason with same name already exists.`,
                            "",
                            false
                        );
                    }
                    if (x.status === 200) {
                        showToast("success", `Exempt reason updated.`, "", false);
                        success = true;
                        dispatch(fetchExemptionReasons());
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export const EnableDisableExemptionReason = (payload, taxCodeId) => async (dispatch, getState) => {
    const { enableLoading } = getState().exemptionReason;
    if (enableLoading) return;
    dispatch(setEnableLoading(taxCodeId));
    await axios
        .put(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-reasons/${taxCodeId}`,
            payload,
            { withCredentials: true }
        )
        .then(x => {
            if (x.status === 200) {
                showToast("success", `Successfully updated the exemption reason.`, "", false);
                dispatch(fetchExemptionReasons());
            } else {
                showToast(
                    "error",
                    `There was a problem updating the exemption reason. Please try again later.`,
                    "",
                    false
                );
            }
        });

    dispatch(setEnableLoading(""));
};

export const fetchEntityUseCodeMapping = () => async (dispatch, getState) => {
    const { isLoadingEntityUseCodeMappings } = getState().exemptionReason;
    if (isLoadingEntityUseCodeMappings) {
        return;
    }

    dispatch(setIsLoadingEntityUseCodeMappings(true));
    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-reasons/entity-use-code-mapping/defaults?&$top=30`,
        { withCredentials: true }
    );

    if (response && response.data) {
        dispatch(setEntityUseCodeMappings(response.data.value));
    }
    dispatch(setIsLoadingEntityUseCodeMappings(false));
};

export default exemptionReasonSlice.reducer;
