import { SBox, SCol, SDatepicker, SIcon, SLoader, SRow, SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { PendingStatuses } from "../../../shared/constants";
import {
    fetchExemptionMatrixReasonValidationApi,
    fetchRegionIdsByDataSetIdAPI,
    fetchValidationCertificateByIdAPI,
    getTaxCodeValidation,
    selectCertificateStatuses,
    selectCustomerCert,
    selectExemptionMatrixValidation,
    selectExposureZones,
    selectHasCertificateFile,
    selectIsAutoValidatingFile,
    selectIsCertPresent,
    selectIsRegionValid,
    selectIsScanningFVS,
    selectLoading,
    selectPassedFVS,
    selectRegionIds,
    selectRegionMessage,
    selectTaxcodeMap,
    selectValidationData,
    setAutoValidationData,
    setCertificate,
    setIsPauseCertificateDialogOpen,
    setIsRegionValid,
    setIsResumeCertificateDialogOpen,
    setIsScanningFVS,
    setIsTaxcodeMap,
    setRegionIds,
    setIsInvalidReasonDialogOpen,
    setCertificateCustomFields,
    getAutoValidationResults,
    setComments,
    setSupplementalFiles,
    getCustomFieldFromSlice,
    selectIsLoadingTaxContentForms,
    fetchTaxContentFormsAsync,
    selectTaxContentForm,
    setIsContentFormDialogOpen,
    selectIsContentFormDialogOpen,
    setTaxContentForm,
    setTaxTemplateTag,
    setIsTaxTypeDialogOpen,
    selectIsTaxTypeDialogOpen,
    selectTaxTemplateTag,
    selectCertificateCommunicationTaxTypes,
    setCertificateCommunicationTaxTypes,
    selectValidationStateCount,
    setValidationStateCount,
} from "../../../app/certificateSlice";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import axios from "../../../axios";
import featureFlag from "../../../featureToggler/featureFlag";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import toast from "../../../hooks/toast";
import {
    errorTag,
    getFormattedDate,
    matchSubscriptions,
    noErrorTag,
    updateSelectedAttribute,
    urlToPageMap,
    errorResponse,
} from "../../../shared/Utils";
import BusinessTypeOptions from "../../certificates/exports/BusinessTypeOptions";
import { advanceSubscription } from "../../navigation/Subscription";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";
import Coverage from "../../sharedComponents/Coverage";
import CustomerTypeAhead from "../../sharedComponents/CustomerTypeAhead";
import SelectExemptionReasonBasedOnRegion from "../../sharedComponents/SelectExemptionReasonBasedOnRegion";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import DeleteCertificateDialog from "../../sharedDialogs/DeleteCertificateDialog";
import PauseResumeCertificateDialog from "../../sharedDialogs/PauseResumeCertificateDialog";
import CertificateComments from "../CertificateComments";
import CertificateCustomField from "../../sharedComponents/CertificateCustomField";
import SyncErrorDialog from "../dialogs/SyncErrorDialog";
import Multi from "../Multi";
import "../validation.css";
import ValidationFileColumn from "../ValidationFileColumn";
import InvalidReasonDialog from "../InvalidReasonDialog";
import CertificateLabel from "../../sharedComponents/CertificateLabel";
import ContentFormDialog from "../ContentFormDialog";
import TaxTypeDialog from "../TaxTypeDialog";
import { getStoreValues } from "../../../app/commonSlice";
import CertificateProductCategories from "../../sharedComponents/CertificateProductCategories";

const [showToast] = toast();

const CertificateDetail = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const certificateCustomFieldRef = useRef();
    const coverageRef = useRef();
    const params = useParams();
    const isLoading = useSelector(selectLoading);
    const certificate = useSelector(selectCustomerCert, shallowEqual);
    const isCertPresent = useSelector(selectIsCertPresent);
    const validationData = useSelector(selectValidationData, shallowEqual);
    const getProductInfo = dispatch(getStoreValues("session.subscriptions"));
    const hasCertificateFile = useSelector(selectHasCertificateFile);
    const setting = dispatch(getStoreValues("setting.settingData", "generalSettings"));
    const [exemptReason, setExemptReason] = useState("");
    const [exposureZone, setExposureZone] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [PoNumber, setPoNumber] = useState("");
    const [single, setIsSingle] = useState(false);
    const [postData, setPostData] = useState({});
    const [effectiveDate, setEffectiveDate] = useState(new Date().toISOString().slice(0, 10));
    const [effectiveDateError, setEffectiveDateError] = useState("");
    const [exemptReasonError, setExemptReasonError] = useState("");
    const [customerError, setCustomerError] = useState("");
    const [regionError, setRegionError] = useState("");
    const [logEntry, setLogEntry] = useState("");
    const [isSyncErrorDialogOpen, setIsSyncErrorDialogOpen] = useState(false);
    const [isTelecom, setIsTelecom] = useState(false);
    const certificateStatuses = useSelector(selectCertificateStatuses, shallowEqual);
    const exposureZones = useSelector(selectExposureZones, shallowEqual);
    const exemptionMatrixList = useSelector(selectExemptionMatrixValidation, shallowEqual);
    const clearRef = useRef(null);
    const [certificateID, setCertificateID] = useState(Number.parseInt(params?.certificateID, 10));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [invalidReasons, setInvalidReasons] = useState([]);
    const dateErrorMsg = "Enter an effective date";
    const invalidDateErrorMsg = "Enter the date in MM/DD/YYYY format";
    const currentDate = new Date();
    const futureEffectiveDateErrorMsg = "The effective date cannot be in the future";
    const [futureEffectiveDate, setFutureEffectiveDate] = useState(false);
    const [invalidEffectiveDate, setInvalidEffectiveDate] = useState(false);
    const saveCertificateButton = classnames({ primary: true, large: true, loading: submitting });
    const secondaryButton = classnames({ secondary: true, large: true });
    const cancelButton = classnames({ tertiary: true, large: true });
    const [certificateStatus, setCertificateStatus] = useState({
        id: certificateStatuses.filter(x => x.name === "COMPLETE")[0]?.id || 3,
        status: "Valid",
    });
    const [businessNumber, setBusinessNumber] = useState(null);
    const [businessNumberType, setBusinessNumberType] = useState("");
    const [businessTypeError, setBusinessTypeError] = useState("");
    const [businessTypeDescription, setBusinessTypeDescription] = useState("");
    const [showBusinessTypeDescription, setShowBusinessTypeDescription] = useState(false);
    const [businessTypeDescriptionError, setBusinessTypeDescriptionError] = useState("");
    const [getCurrentId, getNextId] = useId();
    const [showExemptReasonDescription, setShowExemptReasonDescription] = useState(false);
    const [exemptReasonDescription, setExemptReasonDescription] = useState("");
    const [exemptReasonDescriptionError, setExemptReasonDescriptionError] = useState("");
    const isRegionValid = useSelector(selectIsRegionValid, shallowEqual);
    const isAutoValidatingFile = useSelector(selectIsAutoValidatingFile);
    const isScanningFVS = useSelector(selectIsScanningFVS);
    const passedFVS = useSelector(selectPassedFVS);
    const regionMessage = useSelector(selectRegionMessage);
    const certOnly = matchSubscriptions(
        featureFlag.afcContent.associateTaxTypeData.subscription,
        getProductInfo
    );
    const isTaxcodeMap = useSelector(selectTaxcodeMap);
    const [exemptReasonErrorMessage, setexemptReasonErrorMessage] = useState("");
    const regionIds = useSelector(selectRegionIds, shallowEqual);
    const [validateAllIds, setValidateAllIds] = useState(false);
    const validationStateCount = useSelector(selectValidationStateCount);
    const validatingAllIds = validateAllIds && validationStateCount < exposureZone.length;
    const [isDeleteCertificateDialogOpen, setIsDeleteCertificateDialogOpen] = useState(false);
    const {
        isMulti,
        pausedReasonObj,
        showPauseAlert,
        showRevokedAlert,
        closeInvalidReasonDialog,
        openInvalidReasonDialog,
    } = useOutletContext();
    const [customerDataValidation, setCustomerDataValidation] = useState(false);
    const [multiCertChildList, setMultiCertChildList] = useState([]);
    const [invalidExpirationDate, setInvalidExpirationDate] = useState(false);
    const [isOutgoing, setIsOutgoing] = useState(false);
    const [labelPostData, setLabelPostData] = useState();
    const defaultRedirectURL = isOutgoing ? "search-vendor-certificates" : "search-certificate";
    const isEligibleUserCustCertificateDeleteFlag = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.delete)
    );
    const isEligibleUserVendorCertificateDeleteFlag = dispatch(
        isEligibleUser(featureFlag.vendors.createAndSendCertificates)
    );
    const isEligibleUserPauseFlag = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.pauseResume)
    );
    const isEligibleUserUploadFlag = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.upload)
    );
    const isEligibleUserValidateFlag = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.validate)
    );
    const isEligibleUserSaveAsValidFlag = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.saveAsValid)
    );

    const isLoadingTaxContentForms = useSelector(selectIsLoadingTaxContentForms);
    const taxContentForm = useSelector(selectTaxContentForm);
    const isContentFormDialogOpen = useSelector(selectIsContentFormDialogOpen);
    const isTaxTypeDialogOpen = useSelector(selectIsTaxTypeDialogOpen);
    const taxTemplateTag = useSelector(selectTaxTemplateTag);
    const certificateCommunicationTaxTypes = useSelector(
        selectCertificateCommunicationTaxTypes,
        shallowEqual
    );

    const [isSSTregion, setIsSSTregion] = useState(false);

    const [removedCategories, setRemovedCategories] = useState([]);
    const [addedCategories, setAddedCategories] = useState([]);

    const clearExemptReasonDescription = () => {
        setExemptReasonDescription("");
        setShowExemptReasonDescription(false);
        setExemptReasonDescriptionError("");
    };
    const setCustomerDataValidationValue = useCallback(value => {
        setCustomerDataValidation(value);
    }, []);
    const validationFileColumnComponent = useMemo(
        () => (
            <ValidationFileColumn
                certificateID={certificateID}
                isMulti={isMulti}
                isOutgoing={isOutgoing}
                isUploaderDisabled={!isEligibleUserUploadFlag}
                showRevokedAlert={showRevokedAlert}
            />
        ),
        [certificateID, isMulti, isOutgoing, showRevokedAlert]
    );
    useEffect(() => {
        if (params?.certificateID)
            setCertificateID(prevCertificateId => {
                if (!prevCertificateId) return Number.parseInt(params?.certificateID, 10);
                return prevCertificateId;
            });
    }, [params?.certificateID]);

    useEffect(() => {
        if (!validatingAllIds) {
            setValidateAllIds(false);
            dispatch(setValidationStateCount(0));
        }
    }, [validatingAllIds]);

    const categoriesHandler = category => {
        setAddedCategories([...addedCategories, category]);
    };
    const removeCategoryHandler = category => {
        setRemovedCategories([...removedCategories, category]);
    };

    const handleReasonChange = useCallback((el, calledOnRender) => {
        setExemptReason(prevExemptReason => {
            const reason = typeof el === "object" ? el.detail.item.value : el;
            // do not reset if the exempt reason was not changed
            if (reason === prevExemptReason && !calledOnRender) return prevExemptReason;
            setExemptReasonError("");
            if (reason === "OTHER/CUSTOM") {
                setShowExemptReasonDescription(true);
            } else if (!calledOnRender) {
                clearExemptReasonDescription();
            }
            return reason;
        });
    }, []);

    const updatePostData = useCallback(
        (zone, key, value) => {
            if (isMulti)
                setPostData(prevState => ({
                    ...prevState,
                    [zone]: { ...prevState[zone], [key]: value },
                }));
            else
                setPostData(prevState => ({
                    [zone]: { ...prevState[zone], [key]: value },
                }));
        },
        [isMulti]
    );

    const updateCertificateLabelPostData = useCallback(async (e, add, selectRef) => {
        let value = null;
        const allSelectedValues = await selectRef.current.getValue().then(x => {
            return x;
        });
        if (e.detail.isDeselectAll) {
            setLabelPostData([]);
        } else if (add) {
            value = allSelectedValues;
        } else {
            value = allSelectedValues?.filter(item => {
                return item !== e.detail.item.value;
            });
        }
        setLabelPostData(value);
    }, []);

    const setBusinessTypeData = (businessType, businessNum) => {
        const businessTypeOption = BusinessTypeOptions.find(
            x => x.value.toUpperCase() === businessType.toUpperCase()
        );
        if (businessTypeOption) {
            updateSelectedAttribute(BusinessTypeOptions, businessTypeOption.value);
            setBusinessNumber(businessNum);
            setBusinessNumberType(businessTypeOption.value);
            setShowBusinessTypeDescription(false);
        } else {
            setShowBusinessTypeDescription(true);
            setBusinessNumber(businessNum);
            setBusinessTypeDescription(businessType);
            setBusinessNumberType("Other");
            updateSelectedAttribute(BusinessTypeOptions, "Other");
        }
    };

    const exemptionMatrixAPI = useCallback(
        (ids, documentTypeId) => {
            dispatch(
                fetchExemptionMatrixReasonValidationApi(
                    ids?.length ? ids : [],
                    ids?.length ? showToast : false,
                    documentTypeId
                )
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (PendingStatuses.includes(certificate?.certificateStatus)) {
            navigate(`/review/${certificate.certificateId}`);
        }
    }, [certificate.certificateStatus, certificate.certificateId, navigate]);

    useEffect(() => {
        if (!certificateID) {
            exemptionMatrixAPI([]);
        }
    }, [certificateID, exemptionMatrixAPI]);

    useEffect(() => {
        if (!validationData.data && !certificate?.certificateId && certificateID) {
            dispatch(fetchValidationCertificateByIdAPI(certificateID));
        }
    }, [JSON.stringify(validationData.data), certificateID, dispatch, certificate.certificateId]);

    useEffect(() => {
        if ("certificateId" in certificate) {
            // Set the data on the page if we retrieved certificate data from Redux
            setIsOutgoing(certificate.documentType.outgoing);
            const multiData = [];
            const multiDataExposureZones = [];
            if (certificate?.multiCertificates) {
                certificate?.multiCertificates.forEach(multi => {
                    const { certificateId: id, ...rest } = multi;
                    multiData.push({
                        id,
                        ...rest,
                    });
                    multiDataExposureZones.push({
                        id: multi.exposureZoneId,
                        name: multi.exposureZoneName,
                        certificateId: multi.certificateId,
                    });
                    setPostData(prevPostData => ({
                        ...prevPostData,
                        [multi.exposureZoneId]: {
                            expirationDate: multi.expirationDate,
                            taxNumber: multi.taxNumber,
                            taxNumberType: multi.taxNumberType,
                            taxValidation: {
                                code: multi.taxIdValidationCode,
                                message: multi.taxIdValidationMessage,
                            },
                        },
                    }));
                });
                setMultiCertChildList(multiData);
            }

            if (certificate?.exposureZoneId && certificate?.exposureZoneName) {
                const zones = [
                    {
                        id: certificate?.exposureZoneId,
                        name: certificate?.exposureZoneName,
                        certificateId: certificate?.certificateId,
                        isSST: certificate?.isSst,
                    },
                    ...multiDataExposureZones,
                ];

                setExposureZone(zones);
                exemptionMatrixAPI(
                    zones.map(z => z.id),
                    certificate.documentTypeId
                );
            }

            // Retrive exposure zones from matrix
            if (certificate?.expectedTaxCodeId && certificate?.expectedTaxCodeName) {
                setExemptReason(certificate?.expectedTaxCodeName);
                if (certificate?.expectedTaxCodeName === "OTHER/CUSTOM") {
                    setShowExemptReasonDescription(true);
                }
            }
            if (certificate?.exemptReasonDescription) {
                setExemptReasonDescription(certificate?.exemptReasonDescription);
            }
            if (certificate?.isSingle) {
                setIsSingle(true);
            }
            if (certificate?.signedDate) {
                setEffectiveDate(certificate?.signedDate.slice(0, 10));
                const givenDate = new Date(certificate.signedDate);
                if (givenDate > currentDate) setFutureEffectiveDate(true);
            }
            if (certificate?.purchaseOrderNumber) {
                setPoNumber(certificate?.purchaseOrderNumber);
            }

            if (certificate?.customers && certificate?.customers.length) {
                setCustomers(certificate?.customers);
            }
            if (certificate?.invalidReasons && certificate?.invalidReasons.length) {
                setCertificateStatus(prevCertificateStatus => {
                    const cloneStatus = prevCertificateStatus;
                    cloneStatus.status = "Invalid";
                    return cloneStatus;
                });
                setInvalidReasons(
                    certificate?.invalidReasons.map(invalidReason => invalidReason.id.toString())
                );
            }
            if (certificate?.isValid) {
                setCertificateStatus(prevCertificateStatus => {
                    const cloneStatus = prevCertificateStatus;
                    cloneStatus.status = "Valid";
                    return cloneStatus;
                });
            }

            if (certificate?.certificateStatus?.toUpperCase() === "PENDING-FUTURE") {
                setCertificateStatus(prevCertificateStatus => {
                    const cloneStatus = prevCertificateStatus;
                    cloneStatus.status = "Pending-future";
                    return cloneStatus;
                });
                setInvalidReasons([]);
            }

            if (certificate?.businessNumberType && certificate?.businessNumberType.length > 0) {
                setBusinessTypeData(certificate?.businessNumberType, certificate?.businessNumber);
            }

            if (certificate?.expirationDate && certificate?.exposureZoneName) {
                updatePostData(
                    certificate?.exposureZoneId,
                    "expirationDate",
                    certificate?.expirationDate
                );
            }
            if (certificate?.taxNumber && certificate?.exposureZoneId) {
                updatePostData(certificate?.exposureZoneId, "taxNumber", certificate?.taxNumber);
            }
            if (certificate?.taxNumberType && certificate?.exposureZoneId) {
                updatePostData(
                    certificate?.exposureZoneId,
                    "taxNumberType",
                    certificate?.taxNumberType
                );
            }
            if ("certificateId" in certificate) {
                if (certificate?.avsId) {
                    dispatch(getAutoValidationResults(certificate.avsId));
                }
            }

            if (
                certificate?.expectedTaxCodeId &&
                certificate?.expectedTaxCodeName &&
                certificate?.exposureZoneId &&
                certificate?.exposureZoneName &&
                certificate?.templateTag
            ) {
                dispatch(
                    fetchTaxContentFormsAsync(
                        {
                            name: certificate?.expectedTaxCodeName,
                            isTelecom: certificate?.isTelecom,
                        },
                        [{ name: certificate?.exposureZoneName }],
                        certificate?.templateTag,
                        certificateID,
                        certOnly
                    )
                );
                setIsTelecom(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        certificate.taxNumber,
        certificate.taxNumberType,
        certificate.exposureZoneId,
        certificate.expirationDate,
        certificate.exposureZone,
        certificate.businessNumberType,
        certificate.businessNumber,
        certificate.isValid,
        JSON.stringify(certificate.invalidReasons),
        certificate.customers,
        certificate.exemptReasonDescription,
        certificate.expectedTaxCodeId,
        certificate.expectedTaxCodeName,
        certificate.exposureZoneName,
        certificate.isSingle,
        JSON.stringify(certificate.multiCertificates),
        certificate.purchaseOrderNumber,
        certificate.signedDate,
        certificate.avsId,
        certificate.templateTag,
        dispatch,
        exemptionMatrixAPI,
        updatePostData,
    ]);

    useEffect(() => {
        return () => {
            updateSelectedAttribute(BusinessTypeOptions, "");
            dispatch(setAutoValidationData({ status: "", issues: [], predictions: {} }));
            dispatch(setRegionIds([]));
            dispatch(setIsScanningFVS(false));
            dispatch(setIsInvalidReasonDialogOpen(false));
            dispatch(setCertificateCustomFields([]));
            dispatch(setSupplementalFiles([]));
            dispatch(setComments([]));
            dispatch(setTaxTemplateTag(""));
            dispatch(setTaxContentForm(""));
            dispatch(setCertificateCommunicationTaxTypes([]));
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!validationData.data) {
            if (certificateID) {
                return;
            }
            showToast("error", "Invalid ID. Unable to determine certificate you wish to view.");
            navigate(urlToPageMap[defaultRedirectURL]);
        }
    }, [JSON.stringify(validationData.data), certificateID, navigate]);

    useEffect(() => {
        if (!isCertPresent) {
            showToast("error", "Invalid ID. Unable to determine certificate you wish to view.");
            navigate(urlToPageMap[defaultRedirectURL]);
        }
    }, [isCertPresent, navigate]);

    const exposureZoneNames = exposureZone.map(e => e.name).join(", ");

    useEffect(() => {
        const sstRegions = exposureZone.filter(x => x?.isSST === true).length > 0;
        setIsSSTregion(sstRegions);
        return () => {
            setIsSSTregion(false);
        };
    }, [JSON.stringify(exposureZone)]);

    useEffect(() => {
        if (!isRegionValid) {
            setExemptReasonError("error");
            setexemptReasonErrorMessage(regionMessage);
        }
    }, [isRegionValid, dispatch, regionMessage, JSON.stringify(exposureZoneNames)]);

    useEffect(() => {
        if (certificateStatus.status === "Invalid") {
            if (!exposureZone.length) {
                setRegionError("");
            }

            if (!exemptReason) {
                setExemptReasonError("");
            }

            if (!effectiveDate) {
                setEffectiveDateError("");
            }

            if (!exemptReasonDescription) {
                setExemptReasonDescriptionError("");
            }

            if (!businessNumberType) {
                setBusinessTypeError("");
            }
            if (!businessTypeDescription) {
                setBusinessTypeDescriptionError("");
            }
        }
    }, [
        certificateStatus.status,
        effectiveDate,
        exemptReason,
        exposureZone.length,
        exemptReasonDescription,
        businessNumberType,
        businessTypeDescription,
    ]);

    const handleBusinessTypeChange = el => {
        // do not reset or change state if the type was not changed
        if (businessNumberType === el.detail.item.value) {
            return;
        }
        setBusinessTypeError("");
        setBusinessNumberType(el.detail.item.value);
        setBusinessNumber(parseInt(el.detail.item.number, 10));
        setBusinessTypeDescription("");

        if (el.detail.item.value === "Other") {
            setShowBusinessTypeDescription(true);
        } else {
            setShowBusinessTypeDescription(false);
            setBusinessTypeDescriptionError("");
        }
    };

    const handleBusinessTypeRemove = () => {
        setBusinessNumber(null);
        setBusinessNumberType("");
        setBusinessTypeDescription("");
        setShowBusinessTypeDescription(false);
        setBusinessTypeError("");
        setBusinessTypeDescriptionError("");
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === "businessTypeDescription") {
                    setBusinessTypeDescriptionError("");
                }
                if (event.target.name === "exemptReasonDescription") {
                    setExemptReasonDescriptionError("");
                }
            }
            setter(event.target.value);
        };
    };

    const getTaxValidationCode = zoneId => {
        let code = 0;
        if (certificate?.certificateTaxIdValidation && certificate.exposureZoneId === zoneId) {
            code = certificate.certificateTaxIdValidation.taxIdValidationCode;
        } else if (isMulti) {
            const associatedCert = multiCertChildList.filter(m => m.exposureZoneId === zoneId);
            if (associatedCert.length > 0) {
                code = associatedCert[0]?.taxIdValidationCode;
            }
        }
        return code;
    };
    const getTaxValidationMessage = zoneId => {
        let message = "";
        if (certificate?.certificateTaxIdValidation && certificate.exposureZoneId === zoneId) {
            message = certificate.certificateTaxIdValidation.taxIdValidationMessage;
        } else if (isMulti) {
            const associatedCert = multiCertChildList.filter(m => m.exposureZoneId === zoneId);
            if (associatedCert.length > 0) {
                message = associatedCert[0]?.taxIdValidationMessage;
            }
        }
        return message;
    };

    const addCustomer = useCallback(value => {
        setCustomerError("");
        setCustomers(prevCustomer => [...prevCustomer, ...value]);
    }, []);

    const removeCustomer = useCallback(e => {
        setCustomers(prevCustomer => {
            const customersClone = prevCustomer.slice();
            customersClone.splice(
                customersClone.findIndex(ele => ele.id === parseInt(e.value, 10)),
                1
            );
            return customersClone;
        });
    }, []);

    const handleRegionRemove = useCallback(
        (eventDetail, isDeselectAll) => {
            const value = eventDetail?.value || eventDetail;
            if (isDeselectAll) {
                exemptionMatrixAPI([], certificate.documentTypeId);
                setExposureZone([]);
                setPostData({});
            } else {
                setExposureZone(prevExposureZone => {
                    const newExposureZone = prevExposureZone.filter(
                        zone => zone.id !== parseInt(value, 10)
                    );
                    const ids = newExposureZone.map(ele => ele.id);
                    if (!ids.length) {
                        dispatch(setTaxContentForm(""));
                    }
                    exemptionMatrixAPI(ids, certificate.documentTypeId);
                    return newExposureZone;
                });
                setPostData(prevPostData => {
                    const { [value]: tmp, ...rest } = prevPostData;
                    return rest;
                });
            }
            clearExemptReasonDescription();
        },
        [certificate.documentTypeId, exemptionMatrixAPI]
    );

    const clearRegionData = useCallback(() => {
        setRegionError("");
        setExemptReason("");
        setExemptReasonError("");
        clearExemptReasonDescription();
    }, []);

    let regions = [];
    if (regionIds?.length > 0) {
        regions = exposureZones.filter(item => regionIds.some(d => d.regionId === item.id));
    }
    useEffect(() => {
        if (regionIds?.length > 0) {
            clearRegionData();
            const ids = regions.map(ele => ele.id);
            exemptionMatrixAPI(ids?.length ? ids : [], certificate.documentTypeId);
            if (isMulti) {
                regions.forEach(element => {
                    updatePostData(element.id, "taxNumber", "");
                });
                setExposureZone(regions);
            } else if (exposureZone.length && exposureZone[0]?.id)
                handleRegionRemove(exposureZone[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        regionIds?.length,
        JSON.stringify(regions), // eslint-disable-line
        regions.length,
        exemptionMatrixAPI,
        clearRegionData,
        updatePostData,
        handleRegionRemove,
        exposureZone.length,
        isMulti,
        exposureZone[0]?.id, // eslint-disable-line
    ]);

    const handleRegionAdd = useCallback(
        event => {
            if (!event.isDataEntrySet) {
                setExposureZone(prevExposureZone => {
                    if (prevExposureZone.length > 1) {
                        showToast(
                            "warning",
                            "New regions cannot be added to an existing certificate."
                        );
                        return [...prevExposureZone];
                    }
                    const exposureZoneId = event.value;
                    const ids = isMulti
                        ? [...prevExposureZone.map(z => z.id), exposureZoneId]
                        : [exposureZoneId];
                    const exposureZoneObj = {
                        id: exposureZoneId,
                        name: event.label,
                        isSST: event.isSST,
                    };
                    exemptionMatrixAPI(ids, certificate.documentTypeId);
                    clearRegionData();
                    if (ids.length > 1) {
                        dispatch(setTaxTemplateTag(""));
                        dispatch(setTaxContentForm(""));
                    }
                    if (isMulti) {
                        return [
                            ...new Set([
                                ...prevExposureZone,
                                {
                                    id: exposureZoneObj.id,
                                    name: exposureZoneObj.name,
                                    isSST: exposureZoneObj.isSST,
                                },
                            ]),
                        ];
                    }
                    setPostData({});
                    return [
                        {
                            id: exposureZoneObj.id,
                            name: exposureZoneObj.name,
                            isSST: exposureZoneObj.isSST,
                        },
                    ];
                });
            } else {
                setExposureZone([]);
                dispatch(fetchRegionIdsByDataSetIdAPI(event.value.split("-")[0]));
                dispatch(setTaxTemplateTag(""));
                dispatch(setTaxContentForm(""));
            }
        },
        [isMulti, exemptionMatrixAPI, certificate.documentTypeId, clearRegionData, dispatch]
    );

    const getExemptReason = useCallback(
        value => {
            const result = exemptionMatrixList.find(
                x => x.exemptReason.name.toUpperCase() === value.toUpperCase()
            );
            return result?.exemptReason;
        },
        [JSON.stringify(exemptionMatrixList)] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const cancelExemptions = () => {
        dispatch(setCertificate([]));
        if (isAutoValidatingFile) {
            window.location = urlToPageMap[defaultRedirectURL];
        } else {
            navigate(urlToPageMap[defaultRedirectURL]);
        }
    };

    const handleReasonRemove = useCallback(() => {
        setExemptReason("");
        setShowExemptReasonDescription(false);
        setExemptReasonDescriptionError("");
        setExemptReasonDescription("");
        setExemptReasonError("");
        dispatch(setIsRegionValid(true));
        dispatch(setTaxContentForm(""));
    }, [dispatch]);

    const postCustomFieldsOnly = async () => {
        let customFieldPostData = await dispatch(getCustomFieldFromSlice());
        customFieldPostData = {
            ...customFieldPostData.certificateExistingCustomFieldValues,
            ...customFieldPostData.certificateAddedCustomFieldValues,
        };
        if (Object.keys(customFieldPostData).length > 0) {
            setSubmitting(true);
            let transformedCustomFields = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(customFieldPostData)) {
                let fieldValue = value;
                if (Array.isArray(value)) {
                    fieldValue = value.join("|");
                }
                if (fieldValue !== null) {
                    transformedCustomFields = [
                        ...transformedCustomFields,
                        {
                            FieldId: key,
                            FieldValue: fieldValue,
                        },
                    ];
                }
            }
            await axios
                .put(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/${certificateID}/edit-custom-fields`,
                    transformedCustomFields,
                    {
                        withCredentials: true,
                    }
                )
                .then(response => {
                    if (response.data.success === true) {
                        showToast("success", "Certificate has been successfully updated");
                    } else {
                        showToast(
                            "error",
                            "Certificate data was not successfully updated. Please contact support."
                        );
                    }
                    setSubmitting(false);
                    navigate(`/certificate/${certificateID}`);
                });
        } else {
            showToast("warning", "Please update/add certificate field(s) before trying to save.");
            setSubmitting(false);
        }
    };

    const postExemptions = async updatedCertificateStatus => {
        const errorfields = document
            .getElementById("left-column-width-enforce")
            .getElementsByClassName("error");
        if (errorfields.length > 0) {
            return;
        }
        const certStatus = updatedCertificateStatus || certificateStatus;
        setSubmitting(true);
        const expectedTaxCode = getExemptReason(exemptReason);
        let isTaxcodeMapsSucess = isRegionValid;
        if (!isTaxcodeMap && expectedTaxCode?.id && !expectedTaxCode?.isTelecom) {
            await dispatch(getTaxCodeValidation(expectedTaxCode?.id)).then(response => {
                if (!response?.success) {
                    isTaxcodeMapsSucess = false;
                    setSubmitting(false);
                    dispatch(setIsTaxcodeMap(false));
                } else {
                    dispatch(setIsTaxcodeMap(true));
                }
            });
        }
        if (!isTaxcodeMapsSucess) {
            return;
        }
        const exposureZoneIds = [];
        const certificatePostData = { certificates: [] };
        let newIDsZones = [];
        if (Object.keys(postData).length > 0 && isMulti) {
            let certIds = [certificateID, ...multiCertChildList.map(m => m.id)].sort();
            const existingIDsZones = [
                ...multiCertChildList,
                { id: certificateID, exposureZoneId: certificate.exposureZoneId },
            ];
            let newZoneAdded = false;

            // maintain existing zone and cert id relation if any and add id as 0 to new zones
            Object.keys(postData).forEach(zoneID => {
                let certId;
                const existingIDZone = existingIDsZones.filter(
                    x => x.exposureZoneId === parseInt(zoneID, 10)
                );
                if (existingIDZone[0]?.id) {
                    certId = existingIDZone[0].id;
                    certIds = certIds.filter(c => c !== certId);
                } else {
                    certId = 0;
                    newZoneAdded = true;
                }
                newIDsZones.push({ certId, zoneID });
            });

            // fill existing cert ids first if any into new zones if their cert id = 0
            let isParentAllocated = certIds.find(ids => ids === certificateID);
            if (newZoneAdded && (certIds.length || isParentAllocated)) {
                newIDsZones = newIDsZones.map(i => {
                    const obj = i;
                    if (obj.certId === 0 && isParentAllocated) {
                        obj.certId = certificateID;
                        certIds = certIds.filter(c => c !== certificateID);
                        isParentAllocated = "";
                    } else if (obj.certId === 0 && certIds.length) {
                        [obj.certId, ...certIds] = certIds;
                    }
                    return obj;
                });
            }

            // if no new zones added and if parent certificate id is not allocated
            if (!newZoneAdded && isParentAllocated) {
                newIDsZones[0] = { ...newIDsZones[0], certId: certificateID };
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [exposureZoneId, data] of Object.entries(postData)) {
            exposureZoneIds.push(parseInt(exposureZoneId, 10));
            const certificateData = {
                ...data,
                exposureZoneId: parseInt(exposureZoneId, 10),
                expectedTaxCodeId: expectedTaxCode?.id,
                signedDate: effectiveDate,
                isSingle: single,
                valid: certStatus.status === "Valid",
                certificateStatusId: certStatus.id
                    ? certStatus.id
                    : certificateStatuses.find(x => x.name === "COMPLETE").id,
                businessNumberType: isSSTregion ? businessNumberType : "Other",
                avsId: certificate.avsId,
                id: isMulti
                    ? newIDsZones.filter(i => i.zoneID === exposureZoneId)[0].certId
                    : certificateID,
                templateTag: taxTemplateTag,
                isTelecom,
            };

            certificateData.taxValidation = data.taxValidation;

            if (isSSTregion && businessNumberType === "Other") {
                certificateData.businessNumberType = businessTypeDescription;
            }
            if (exemptReason === "OTHER/CUSTOM") {
                certificateData.exemptReasonDescription = exemptReasonDescription;
            }
            certificateData.businessNumber = businessNumber;
            certificatePostData.certificates.push(certificateData);
        }

        if (
            !Object.keys(postData).length &&
            (certStatus.status === "Invalid" || certStatus.status === "Pending")
        ) {
            const certificateData = {
                exposureZoneId: null,
                expirationDate: null,
                taxNumber: null,
                expectedTaxCodeId: expectedTaxCode?.id,
                signedDate: effectiveDate,
                isSingle: single,
                valid: false,
                certificateStatusId: certStatus.id
                    ? certStatus.id
                    : certificateStatuses.find(x => x.name === "COMPLETE").id,
                businessNumberType,
                avsId: certificate.avsId,
                id: certificateID,
                isTelecom,
            };
            if (businessNumberType === "Other") {
                certificateData.businessNumberType = businessTypeDescription;
            }
            if (exemptReason === "OTHER/CUSTOM") {
                certificateData.exemptReasonDescription = exemptReasonDescription;
            }
            certificateData.businessNumber = businessNumber;
            certificatePostData.certificates.push(certificateData);
        }

        if (addedCategories.length > 0 && setting.exemptionCategory) {
            certificatePostData.exemptProductCategoriesAdded = addedCategories;
        }

        if (removedCategories.length > 0 && setting.exemptionCategory) {
            certificatePostData.exemptProductCategoriesRemoved = removedCategories;
        }

        if (PoNumber) {
            certificatePostData.purchaseOrderNumber = { purchaseOrderNumber: PoNumber };
        }

        const customerIds = customers.map(customer => customer.id);
        // Calculate database ids added and removed so API doesn't have to do database lookups
        const certificateCustomerIds = certificate.customers
            ? certificate.customers.map(customer => customer.id)
            : [];
        certificatePostData.customerIdsRemoved = certificateCustomerIds.filter(
            c => !customerIds.includes(c)
        );

        const certificateInvalidReasonIds = certificate.invalidReasons
            ? certificate.invalidReasons.map(reason => reason.id)
            : [];
        // Delete all invalid reasons if invalid reasons on file and not saving certificate as invalid
        if (
            certStatus.status !== "Invalid" &&
            (certificateInvalidReasonIds.length || invalidReasons.length)
        ) {
            if (certificateInvalidReasonIds.length) {
                certificatePostData.invalidReasonIdsRemoved = certificateInvalidReasonIds;
            }
            if (invalidReasons.length) {
                setInvalidReasons([]);
            }
        } else {
            certificatePostData.invalidReasonIdsRemoved = certificateInvalidReasonIds.filter(
                r => !invalidReasons.includes(r)
            );
            certificatePostData.invalidReasonIdsAdded = invalidReasons.filter(
                r => !certificateInvalidReasonIds.includes(r)
            );
        }

        // exposureZonesId to be sent
        let zoneIds;
        if (isMulti) zoneIds = newIDsZones.map(i => parseInt(i.zoneID, 10));
        else if (Object.keys(postData).length === 1) {
            zoneIds = exposureZone.map(z => z.id);
        } else zoneIds = [];
        certificatePostData.exposureZoneIds = zoneIds;

        let customFieldPostData = await dispatch(getCustomFieldFromSlice());
        customFieldPostData = {
            ...customFieldPostData.certificateExistingCustomFieldValues,
            ...customFieldPostData.certificateAddedCustomFieldValues,
        };
        if (Object.keys(customFieldPostData).length > 0) {
            const transformedCustomFields = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(customFieldPostData)) {
                let fieldValue = value;
                if (Array.isArray(value)) {
                    fieldValue = value.join("|");
                }
                if (fieldValue !== null) transformedCustomFields[key] = fieldValue;
            }
            certificatePostData.CertificateCustomFields = transformedCustomFields;
        }

        if (certificatePostData?.certificates?.length) {
            // This block will be used when updating a certificate that is completed
            certificatePostData.customerIdsAdded = customerIds.filter(
                c => !certificateCustomerIds.includes(c)
            );
            certificatePostData.customerIds = certificatePostData.customerIdsAdded;

            if (isSSTregion === false) {
                certificatePostData.businessNumberType = "Other";
                certificatePostData.businessTypeDescription = "Other";
            }
            if (labelPostData) {
                certificatePostData.certificateCodes = labelPostData;
            }

            certificatePostData.CommunicationTaxTypes = certificateCommunicationTaxTypes.map(
                c => c.id
            );

            await axios
                .put(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/validation`,
                    certificatePostData,
                    {
                        withCredentials: true,
                    }
                )
                .then(response => {
                    // Need to work on messages
                    if (errorResponse(response?.data[0])?.code === "AvataxIntegrationError") {
                        setSubmitting(false);
                        setLogEntry(response?.data[0]?.description);
                        setIsSyncErrorDialogOpen(true);
                    } else if (response.data.success && response.data.message) {
                        showToast(
                            "success",
                            response.data?.message
                                ? response.data.message
                                : "Successfully added certificate."
                        );
                        setSubmitting(false);
                        cancelExemptions();
                    } else {
                        setSubmitting(false);
                        cancelExemptions();
                    }
                });
        } else {
            cancelExemptions();
        }
    };

    const handleStatusChange = value => {
        const certState = {};
        let status = {};
        if (certificateStatuses && certificateStatuses.length) {
            switch (value) {
                case "Invalid":
                    switch (futureEffectiveDate) {
                        case true:
                            status = certificateStatuses.find(x => x.name === "PENDING-FUTURE");
                            certState.id = status.id;
                            certState.status = "Invalid";
                            break;
                        default:
                            status = certificateStatuses.find(x => x.name === "COMPLETE");
                            certState.id = status.id;
                            certState.status = "Invalid";
                            break;
                    }
                    break;
                case "Pending":
                    status = certificateStatuses.find(x => x.name === "PENDING");
                    certState.id = status.id;
                    certState.status = "Pending";
                    break;
                default:
                    switch (futureEffectiveDate) {
                        case true:
                            status = certificateStatuses.find(x => x.name === "PENDING-FUTURE");
                            certState.id = status.id;
                            certState.status = "Valid";
                            break;
                        default:
                            status = certificateStatuses.find(x => x.name === "COMPLETE");
                            certState.id = status.id;
                            certState.status = "Valid";
                    }
            }
            setCertificateStatus(certState);
        }
        return certState;
    };

    const validationHandler = value => {
        setSubmitted(true);
        const updatedCertificateStatus = handleStatusChange(value);
        let formValid = true;
        if (
            updatedCertificateStatus.status !== "Invalid" &&
            updatedCertificateStatus.status !== "Pending"
        ) {
            if (isSSTregion && !businessNumberType) {
                setBusinessTypeError("error");
                formValid = false;
            }

            if (isSSTregion && businessNumberType === "Other" && businessTypeDescription === "") {
                setBusinessTypeDescriptionError("error");
                formValid = false;
            }

            if (exemptReason === "OTHER/CUSTOM" && exemptReasonDescription === "") {
                setExemptReasonDescriptionError("error");
                formValid = false;
            }

            if (!exposureZone.length) {
                setRegionError("error");
                formValid = false;
            }

            if (!isRegionValid) {
                formValid = false;
            }

            if (!exemptReason) {
                setExemptReasonError("error");
                setexemptReasonErrorMessage("Enter a reason for the exemption");
                formValid = false;
            }

            if (!effectiveDate) {
                if (invalidEffectiveDate) setEffectiveDateError(invalidDateErrorMsg);
                else setEffectiveDateError(dateErrorMsg);
                formValid = false;
            }

            if (customers && customers.length === 0) {
                setCustomerError("error");
                formValid = false;
            }

            if (single && !PoNumber) {
                formValid = false;
            }

            if (PoNumber && coverageRef.current.validatePoNumber()) {
                formValid = false;
            }

            Object.values(postData).every(data => {
                if (!data.taxNumber && setting.requireTaxId === true) {
                    formValid = false;
                }
                if (!data.taxNumberType && setting.requireTaxId === true) {
                    formValid = false;
                }
                if (!data.expirationDate) {
                    formValid = false;
                }
                if (data.expirationDate === "manual" || effectiveDate > data.expirationDate) {
                    formValid = false;
                }

                return true;
            });
        }

        if (effectiveDate && futureEffectiveDate && certificate.certificateStatus === "COMPLETE") {
            setEffectiveDateError(futureEffectiveDateErrorMsg);
            formValid = false;
        }

        if (updatedCertificateStatus.status !== "Pending") {
            if (customerDataValidation) {
                formValid = false;
            }

            if (!formValid) {
                return;
            }
        }

        if (
            matchSubscriptions(
                featureFlag.settings.requireCertificateImage.subscription,
                getProductInfo
            ) &&
            setting.requireCertificateImage === null &&
            !hasCertificateFile
        ) {
            showToast(
                "error",
                "Based on your settings, certificate images are required to be attached to certificate records. Add a certificate image and save again."
            );
            return;
        }
        if (updatedCertificateStatus.status === "Invalid") {
            if (regionMessage?.length && regionMessage !== "Certificate exempt reason exists") {
                formValid = false;
            }
            if (businessNumberType === "Other" && businessTypeDescription === "") {
                setBusinessTypeDescriptionError("");
            }
            Object.values(postData).every(data => {
                if (data.expirationDate === "manual" || effectiveDate > data.expirationDate) {
                    formValid = false;
                }
                return true;
            });
            if (invalidExpirationDate) {
                formValid = false;
            }
            if (formValid) {
                openInvalidReasonDialog();
            } else {
                closeInvalidReasonDialog();
            }
        } else if (!isOutgoing) {
            postExemptions(updatedCertificateStatus);
        } else {
            postCustomFieldsOnly();
        }
    };

    const handleDateChange = e => {
        if (e?.detail?.value) {
            const givenDate = new Date(e.detail.value);
            setEffectiveDate(e.detail.value);
            setEffectiveDateError("");
            if (givenDate > currentDate) {
                setFutureEffectiveDate(true);
            } else {
                setFutureEffectiveDate(false);
            }
        } else if (e?.detail?.invalidInput) {
            setInvalidEffectiveDate(true);
            setEffectiveDate("");
            setEffectiveDateError(invalidDateErrorMsg);
        } else {
            setEffectiveDate("");
            setEffectiveDateError(dateErrorMsg);
        }
    };

    const getTaxNumber = zoneId => {
        if (postData && postData[zoneId] && postData[zoneId]?.taxNumber)
            return postData[zoneId].taxNumber;
        return "";
    };
    const getTaxNumberType = zoneId => {
        if (postData && postData[zoneId] && postData[zoneId]?.taxNumberType)
            return postData[zoneId].taxNumberType;
        return "";
    };

    const checkExistingZone = zoneId => {
        if (
            certificate?.exposureZoneId === zoneId ||
            multiCertChildList.find(multi => multi.exposureZoneId === zoneId)
        ) {
            return true;
        }
        return false;
    };

    const getExpirationDate = zone => {
        if (certificate?.exposureZoneName === zone && certificate.expirationDate) {
            return getFormattedDate(certificate.expirationDate, true);
        }
        if (isMulti) {
            const associatedCert = multiCertChildList.filter(
                m => m.exposureZoneName === zone && m?.expirationDate
            );
            return getFormattedDate(associatedCert[0]?.expirationDate, true);
        }
        return "";
    };

    const handleReasonSelection = useCallback(
        event => {
            dispatch(setIsTaxcodeMap(false));
            if (event?.detail?.item?.value) {
                dispatch(setIsRegionValid(true));
                const expectedTaxCode = getExemptReason(event.detail.item.value);
                const expectedTaxCodeId = expectedTaxCode?.id;
                if (expectedTaxCodeId && !expectedTaxCode?.isTelecom) {
                    dispatch(getTaxCodeValidation(expectedTaxCodeId));
                    setIsTelecom(false);
                }
                if (expectedTaxCode?.isTelecom) {
                    setIsTelecom(true);
                }

                dispatch(
                    fetchTaxContentFormsAsync(
                        expectedTaxCode,
                        [{ name: exposureZone[0]?.name }],
                        null,
                        null,
                        certOnly
                    )
                );
            }
            handleReasonChange(event);
        },
        [handleReasonChange, dispatch, getExemptReason]
    );

    const openPauseResumeCertificateDialog = () => {
        if (certificate?.invalidReasons) {
            if (pausedReasonObj >= 0) {
                dispatch(setIsResumeCertificateDialogOpen(true));
            } else {
                dispatch(setIsPauseCertificateDialogOpen(true));
            }
        }
    };

    const getTaxTypeRegionOptionList = useCallback(
        currentRegionName => {
            const optionList = exposureZone.map(element => {
                const selectOption = {
                    label: element.name,
                    value: element.id.toString(),
                    selected: element.name === currentRegionName,
                };
                return selectOption;
            });
            return optionList;
        },
        [exposureZone]
    );

    let ValidationDOM = null;

    if (submitting || isLoading) {
        ValidationDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else if ("certificateId" in certificate) {
        ValidationDOM = (
            <React.Fragment>
                <Helmet>
                    <title>{dispatch(getPageTitle("Validation"))}</title>
                </Helmet>

                <SRow className="margin-top-xl">
                    <SCol span="5" id="left-column-width-enforce">
                        <SRow>
                            <SCol>
                                <SRow span="auto">
                                    <SCol>
                                        <label className="font-semibold pad-bottom-none">
                                            Status
                                        </label>
                                    </SCol>
                                </SRow>
                                <SRow span="auto">
                                    <SCol>
                                        {CertificateStatusTag(
                                            certificate,
                                            null,
                                            true,
                                            certificate?.certificateStatus
                                        )}
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <CustomerTypeAhead
                                            handleAdd={addCustomer}
                                            handleRemove={removeCustomer}
                                            value={customers}
                                            error={customerError}
                                            certificateId={certificateID}
                                            addNewCustomer
                                            setCustomerDataValidation={
                                                setCustomerDataValidationValue
                                            }
                                            vendor={isOutgoing}
                                            label={isOutgoing ? "Vendors" : "Customers"}
                                            disabled={isOutgoing || showRevokedAlert}
                                        />
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <SelectExposureZone
                                            onAdd={handleRegionAdd}
                                            onRemove={handleRegionRemove}
                                            value={exposureZone}
                                            multiple={isMulti}
                                            exposureZoneDisabled={isOutgoing || showRevokedAlert}
                                            isDataEntrySet={isMulti}
                                            error={regionError}
                                            ref={clearRef}
                                        />
                                        <div className="input-msg" hidden={!regionError}>
                                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                                            <span className="top-xs">
                                                Enter a region covered by this certificate
                                            </span>
                                        </div>
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <SelectExemptionReasonBasedOnRegion
                                            onAdd={handleReasonSelection}
                                            onRemove={handleReasonRemove}
                                            error={exemptReasonError.length ? errorTag : ""}
                                            multiple={false}
                                            value={exemptReason}
                                            disableExemptReason={isOutgoing || showRevokedAlert}
                                        />
                                        <div className="input-msg" hidden={!exemptReasonError}>
                                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                                            <span className="top-xs">
                                                {exemptReasonErrorMessage}
                                            </span>
                                        </div>
                                    </SCol>
                                </SRow>
                                {showExemptReasonDescription ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <fieldset>
                                                <label htmlFor="exemptReasonDescription">
                                                    Description
                                                </label>
                                                <input
                                                    id="exemptReasonDescription"
                                                    name="exemptReasonDescription"
                                                    disabled={isOutgoing || showRevokedAlert}
                                                    onChange={handleChangeInput(
                                                        setExemptReasonDescription
                                                    )}
                                                    value={exemptReasonDescription}
                                                    className={
                                                        exemptReasonDescriptionError || noErrorTag
                                                    }
                                                />
                                                {exemptReasonDescriptionError ? (
                                                    <div className="input-msg">
                                                        <SIcon
                                                            name="alert-circle-filled"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="top-xs">
                                                            Enter description
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </fieldset>
                                        </SCol>
                                    </SRow>
                                ) : null}

                                {taxContentForm ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <label htmlFor={getNextId()}>
                                                Certificate form
                                                <button
                                                    onClick={() =>
                                                        dispatch(setIsContentFormDialogOpen(true))
                                                    }
                                                    className="link small-link margin-left-sm">
                                                    View form options
                                                </button>
                                            </label>
                                            <span className="taxContentForm">{taxContentForm}</span>
                                            <button
                                                onClick={() =>
                                                    dispatch(setIsTaxTypeDialogOpen(true))
                                                }
                                                className="link small-link">
                                                Taxes exempted by this form
                                            </button>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                {isLoadingTaxContentForms ? (
                                    <SLoader className="margin-top-sm" loading />
                                ) : null}

                                {isSSTregion ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <label htmlFor={getNextId()} id="lbl-businessType">
                                                Business type
                                            </label>
                                            <SSelect
                                                name="businessType"
                                                inputId={getCurrentId()}
                                                selectionOptional
                                                optionsList={BusinessTypeOptions}
                                                onS-select={e => handleBusinessTypeChange(e)}
                                                onS-deselect={e => handleBusinessTypeRemove(e)}
                                                className={
                                                    businessTypeError ? errorTag : noErrorTag
                                                }
                                                disabled={
                                                    isOutgoing || showRevokedAlert
                                                        ? "disabled"
                                                        : null
                                                }
                                            />
                                            <div className="input-msg">
                                                <SIcon
                                                    name="alert-circle-filled"
                                                    aria-hidden="true"
                                                />
                                                <span className="top-xs">
                                                    Enter a business type
                                                </span>
                                            </div>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                {showBusinessTypeDescription ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <fieldset>
                                                <label
                                                    htmlFor="businessTypeDescription"
                                                    id="lbl-businessTypeDescription">
                                                    Description
                                                </label>
                                                <input
                                                    name="businessTypeDescription"
                                                    id="businessTypeDescription"
                                                    type="text"
                                                    onChange={handleChangeInput(
                                                        setBusinessTypeDescription
                                                    )}
                                                    value={businessTypeDescription}
                                                    className={
                                                        businessTypeDescriptionError || noErrorTag
                                                    }
                                                    disabled={isOutgoing || showRevokedAlert}
                                                />
                                                <div
                                                    className="input-msg"
                                                    hidden={!businessTypeDescriptionError.length}>
                                                    <SIcon
                                                        name="alert-circle-filled"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="top-xs">
                                                        Enter description
                                                    </span>
                                                </div>
                                            </fieldset>
                                        </SCol>
                                    </SRow>
                                ) : null}

                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <label htmlFor="effective-date-input">Effective</label>
                                        <SRow>
                                            <SCol span="3" className="pad-bottom-none">
                                                <SDatepicker
                                                    inputid="effective-date-input"
                                                    inputname="date"
                                                    disabled={isOutgoing || showRevokedAlert}
                                                    className={
                                                        submitted && effectiveDateError
                                                            ? "error"
                                                            : "effectiveDate"
                                                    }
                                                    onS-select={e => {
                                                        handleDateChange(e);
                                                    }}
                                                    value={effectiveDate}
                                                    onS-error={e => {
                                                        handleDateChange(e);
                                                    }}
                                                    onS-deselect={e => {
                                                        handleDateChange(e);
                                                    }}
                                                />
                                            </SCol>
                                        </SRow>
                                        <SRow>
                                            <SCol>
                                                {submitted && effectiveDateError ? (
                                                    <div className="input-msg effective-date-error">
                                                        <SIcon
                                                            name="alert-circle-filled"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="top-xs">
                                                            {effectiveDateError}
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </SCol>
                                        </SRow>
                                    </SCol>
                                    {exemptReason.length > 0 && exposureZone.length > 1 ? (
                                        <SCol>
                                            <span className="right">
                                                {validatingAllIds ? (
                                                    <span className="text-sm-strong">
                                                        <i>Verifying all ID numbers...</i>
                                                    </span>
                                                ) : (
                                                    <button
                                                        className="link font-semibold"
                                                        onClick={() => setValidateAllIds(true)}>
                                                        Verify all regional ID numbers
                                                    </button>
                                                )}
                                            </span>
                                        </SCol>
                                    ) : null}
                                </SRow>
                                <SRow
                                    hidden={exemptReason.length === 0 || exposureZone.length === 0}>
                                    <SCol span="12" className="margin-top-sm">
                                        <div className="relative-wrap">
                                            {exposureZone.map(zone => {
                                                return (
                                                    <Multi
                                                        key={zone.id}
                                                        disableMulti={
                                                            isOutgoing || showRevokedAlert
                                                        }
                                                        disabledExpirationDate={showRevokedAlert}
                                                        zoneName={zone.name}
                                                        certificateId={zone.certificateId}
                                                        zoneId={zone.id}
                                                        taxNumber={getTaxNumber(zone.id)}
                                                        taxNumberType={getTaxNumberType(zone.id)}
                                                        taxValidationCode={getTaxValidationCode(
                                                            zone.id
                                                        )}
                                                        taxValidationMessage={getTaxValidationMessage(
                                                            zone.id
                                                        )}
                                                        exemptReason={exemptReason}
                                                        effectiveDate={effectiveDate}
                                                        existingExpirationDate={getExpirationDate(
                                                            zone.name
                                                        )}
                                                        submitted={submitted}
                                                        updatePostData={updatePostData}
                                                        certStatus={certificateStatus.status}
                                                        isExistingZone={checkExistingZone(zone.id)}
                                                        existingEffectiveDate={
                                                            certificate.signedDate
                                                        }
                                                        existingExpectedTaxCodeName={
                                                            certificate.expectedTaxCodeName
                                                        }
                                                        existingExposureZoneName={
                                                            certificate.exposureZoneName
                                                        }
                                                        setInvalidExpirationDate={
                                                            setInvalidExpirationDate
                                                        }
                                                        validateAllIds={validateAllIds}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="10">
                                        <Coverage
                                            single={single}
                                            setIsSingle={setIsSingle}
                                            PoNumber={PoNumber}
                                            setPoNumber={setPoNumber}
                                            submitted={submitted}
                                            certStatus={certificateStatus.status}
                                            disabled={isOutgoing || showRevokedAlert}
                                            ref={coverageRef}
                                        />
                                    </SCol>
                                </SRow>
                                {setting.exemptionCategory ? (
                                    <SRow>
                                        <SCol span="10">
                                            <CertificateProductCategories
                                                newCategories={addedCategories}
                                                addCategory={categoriesHandler}
                                                certificateID={certificateID}
                                                deleteCategory={removeCategoryHandler}
                                                deletedCategory={removedCategories}
                                                allCategory={setRemovedCategories}
                                            />
                                        </SCol>
                                    </SRow>
                                ) : null}
                                <FeatureToggler category="validatedCertificate" id="comments">
                                    <CertificateComments
                                        certificateID={certificateID}
                                        disabledComments={showRevokedAlert}
                                    />
                                </FeatureToggler>
                                <FeatureToggler category="validatedCertificate" id="customFields">
                                    <CertificateCustomField
                                        certificateID={certificateID}
                                        disabledCustom={showRevokedAlert}
                                        ref={certificateCustomFieldRef}
                                    />
                                </FeatureToggler>
                                <FeatureToggler
                                    category="validatedCertificate"
                                    id="certificateLabels">
                                    <CertificateLabel
                                        updateCertificateLabel={updateCertificateLabelPostData}
                                        certificateID={certificateID}
                                        disabledLabel={showRevokedAlert}
                                    />
                                </FeatureToggler>
                            </SCol>
                        </SRow>
                    </SCol>
                    <SCol span="7">{validationFileColumnComponent}</SCol>
                    <SCol span="12" className="pad-bottom-none">
                        {isScanningFVS && (
                            <SBox className="margin-bottom-md">
                                <SIcon
                                    name="security-check"
                                    className="margin-right-xs"
                                    aria-hidden="true"
                                />
                                <strong>
                                    Just taking a moment to check for vulnerabilities...
                                </strong>
                            </SBox>
                        )}
                        <hr className="margin-all-none" />
                        <div className="margin-top-lg">
                            {isOutgoing ? (
                                <FeatureToggler category="validatedCertificate" id="vendorSave">
                                    <button
                                        className={saveCertificateButton}
                                        onClick={() => validationHandler()}>
                                        Save
                                    </button>
                                </FeatureToggler>
                            ) : (
                                <FeatureToggler
                                    category="validatedCertificate"
                                    id="validate"
                                    firstChild={
                                        <>
                                            <button
                                                className={saveCertificateButton}
                                                id="save-valid-certificate-btn"
                                                disabled={
                                                    isScanningFVS ||
                                                    !passedFVS ||
                                                    showPauseAlert ||
                                                    isOutgoing ||
                                                    !isEligibleUserValidateFlag ||
                                                    showRevokedAlert
                                                }
                                                onClick={() => validationHandler("Valid")}
                                                type="primary">
                                                Save as valid
                                            </button>
                                            <button
                                                className={secondaryButton}
                                                id="save-invalid-certificate-btn"
                                                disabled={
                                                    isScanningFVS ||
                                                    !passedFVS ||
                                                    showPauseAlert ||
                                                    isOutgoing ||
                                                    !isEligibleUserValidateFlag ||
                                                    showRevokedAlert
                                                }
                                                onClick={() => validationHandler("Invalid")}
                                                type="secondary">
                                                Save as invalid
                                            </button>
                                        </>
                                    }
                                    secondChild={
                                        <button
                                            className={saveCertificateButton}
                                            id="add-certificate-btn"
                                            disabled={
                                                isScanningFVS ||
                                                !passedFVS ||
                                                showPauseAlert ||
                                                isOutgoing ||
                                                !isEligibleUserSaveAsValidFlag ||
                                                showRevokedAlert
                                            }
                                            onClick={() => validationHandler("Valid")}
                                            type="primary">
                                            Save
                                        </button>
                                    }
                                />
                            )}

                            <button onClick={cancelExemptions} className={cancelButton}>
                                Cancel
                            </button>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol className="margin-top-md">
                        {certificateID ? (
                            <FeatureToggler category="validatedCertificate" id="pauseResume">
                                <button
                                    id="pauseResume"
                                    className="link inline font-semibold"
                                    disabled={
                                        isOutgoing || !isEligibleUserPauseFlag || showRevokedAlert
                                    }
                                    onClick={openPauseResumeCertificateDialog}>
                                    <span>
                                        {!showPauseAlert ? "Pause " : "Resume use of "}
                                        this certificate
                                    </span>
                                </button>
                            </FeatureToggler>
                        ) : null}

                        {certificateID ? (
                            <>
                                <span className="divider-right margin-left-xs" />
                                <span role="separator" aria-orientation="vertical" />
                                <button
                                    className="link inline font-semibold"
                                    disabled={
                                        isOutgoing
                                            ? !isEligibleUserVendorCertificateDeleteFlag
                                            : !isEligibleUserCustCertificateDeleteFlag
                                    }
                                    onClick={() => setIsDeleteCertificateDialogOpen(true)}>
                                    <span className="margin-left-xs">Delete this certificate</span>
                                </button>
                            </>
                        ) : null}
                    </SCol>
                </SRow>
                {isSyncErrorDialogOpen && (
                    <SyncErrorDialog
                        isSyncErrorDialogOpen={isSyncErrorDialogOpen}
                        setIsSyncErrorDialogOpen={setIsSyncErrorDialogOpen}
                        logEntry={logEntry}
                    />
                )}
                {isDeleteCertificateDialogOpen && (
                    <DeleteCertificateDialog
                        isDeleteCertificateDialogOpen={isDeleteCertificateDialogOpen}
                        setIsDeleteCertificateDialogOpen={setIsDeleteCertificateDialogOpen}
                        certificateIds={certificateID}
                        navigate={() =>
                            matchSubscriptions(advanceSubscription, getProductInfo) && !isOutgoing
                                ? navigate(urlToPageMap["search-pending"])
                                : navigate(urlToPageMap[defaultRedirectURL])
                        }
                    />
                )}
                <PauseResumeCertificateDialog certificateID={certificateID} />
                <InvalidReasonDialog
                    postExemptions={postExemptions}
                    invalidReasonsString={JSON.stringify(invalidReasons)}
                    setInvalidReasons={setInvalidReasons}
                    closeInvalidReasonDialog={closeInvalidReasonDialog}
                    openInvalidReasonDialog={openInvalidReasonDialog}
                />
                {isContentFormDialogOpen ? (
                    <ContentFormDialog
                        exposureZone={exposureZone}
                        exemptReason={exemptReason}
                        certificateID={certificateID}
                    />
                ) : null}
                {isTaxTypeDialogOpen ? (
                    <TaxTypeDialog
                        certificateID={certificateID}
                        exposureZoneId={parseInt(exposureZone[0].id, 10)}
                        exposureZoneName={exposureZone[0].name}
                        isMulti={exposureZone.length > 1}
                        getTaxTypeRegionOptionList={getTaxTypeRegionOptionList}
                    />
                ) : null}
            </React.Fragment>
        );
    }
    return ValidationDOM;
});

export default CertificateDetail;
