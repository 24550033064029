import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL, fixSearchStringForPostgres, errorResponse } from "../shared/Utils";

export const customerCertificateSlice = createSlice({
    name: "customerCertificate",
    initialState: {
        isloading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        sortColumn: ["certificateId", true],
        isRequestFromCustomersCertificateGrid: false,
        showOnlyActiveCertificate: true,
        isAddRegionDialogOpen: false,
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsRequestFromCustomersCertificateGrid: (state, action) => {
            state.isRequestFromCustomersCertificateGrid = action.payload;
        },
        setShowOnlyActiveCertificate: (state, action) => {
            state.showOnlyActiveCertificate = action.payload;
        },
        setIsAddRegionDialogOpen: (state, action) => {
            state.isAddRegionDialogOpen = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setIsRequestFromCustomersCertificateGrid,
    setShowOnlyActiveCertificate,
    setIsAddRegionDialogOpen,
} = customerCertificateSlice.actions;

export const selectPage = state => {
    return state.customerCertificate.page;
};

export const selectLoading = state => {
    return state.customerCertificate.isloading;
};

export const selectSortColumn = state => {
    return state.customerCertificate.sortColumn;
};

export const selectIsRequestFromCustomersCertificateGrid = state => {
    return state.customerCertificate.isRequestFromCustomersCertificateGrid;
};

export const selectShowOnlyActiveCertificate = state => {
    return state.customerCertificate.showOnlyActiveCertificate;
};

export const selectIsAddRegionDialogOpen = state => {
    return state.customerCertificate.isAddRegionDialogOpen;
};

export const fetchCustomerExemptionsDetails =
    (top = 20, skip, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn, showOnlyActiveCertificate } = getState().customerCertificate;

        const { customer } = getState().customer;
        const fetchURL = showOnlyActiveCertificate
            ? `?showOnlyActiveCertificate=true&isOutgoing=${customer.isVendor}`
            : `?showOnlyActiveCertificate=false&isOutgoing=${customer.isVendor}`;

        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "DESC" : "ASC"}`;
        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer/${customer.id}/exemptions-details${fetchURL}`,
            "",
            "",
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else if (errorResponse(response.data[0])?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: skip,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: top,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export const fetchCustomerCertificates =
    (top = 20, skip, paginateDetails, id, isRetail = false) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn, showOnlyActiveCertificate } = getState().customerCertificate;

        const { customer } = getState().customer;
        let fetchURL = "";
        if (isRetail) {
            // fetchURL = `${id}?getPrimaryCertificatesOnly=true&isOutgoing=${false}&locationId=${locationId}`
            fetchURL = `${id}?certificateStatusEnum=PendingOrActiveComplete&isOutgoing=${false}`;
        } else {
            fetchURL = showOnlyActiveCertificate
                ? `${customer.id}?certificateStatusEnum=ActiveComplete&isOutgoing=${customer.isVendor}`
                : `${customer.id}?certificateStatusEnum=PendingFutureOrComplete&isOutgoing=${customer.isVendor}`;
        }
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "DESC" : "ASC"}`;
        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/customer/${fetchURL}`,
            "",
            "",
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: skip,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: top,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export const customerCertificateSearch =
    (customerNumber, pageSize = 20, pageNumber = 1, paginateDetails, isPrimary = true) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn } = getState().customerCertificate;
        if (isloading) {
            return;
        }

        dispatch(setLoading(true));
        const response = await axios.post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/search`,
            {
                customerCodes: [fixSearchStringForPostgres(customerNumber)],
                isPrimary,
                pageSize,
                pageNumber,
                orderBy: sortColumn[0],
                orderByDirection: sortColumn[1] ? "ASC" : "DESC",
                nameOrCode: "",
                purchaseOrderNumber: "",
                isBillTo: false,
                isShipTo: false,
                certificateImageExists: 0,
                returnEmptyEmailRecords: false,
                customerCreatedFrom: null,
                customerCreatedTo: null,
                customerModifiedFrom: null,
                customerModifiedTo: null,
                certificateCreatedFrom: null,
                certificateCreatedTo: null,
                certificateModifiedFrom: null,
                certificateModifiedTo: null,
                certificateEffectiveFrom: null,
                certificateEffectiveTo: null,
                certificateIds: [],
                status: [],
                region: [],
                customerRegion: [],
                exemptionReason: [],
                certificateHasCustomers: 0,
                activeCertificate: 0,
            },
            { withCredentials: true }
        );
        if (!response.data) {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setLoading(false));
    };

export default customerCertificateSlice.reducer;
